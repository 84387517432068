import React, { Component } from 'react'

import _ from 'lodash';
import { saveAs } from 'file-saver';

import createPattern from '../../helper/createPattern';

export default class Pattern extends Component {


    constructor(props){
        super(props);

        this.saveFile = this.saveFile.bind(this);
    }

    state = {
        radius : 220,
        slices : 20,
        zoom : 1.1,
        minZoom : 1,
        rotate : 0 * Math.PI / 180,
        width : 3508,
        height : 2480,
        imageWidth : null,
        imageHeight : null,
        maxXOffset : 0,
        maxYOffset : 0,
        xOffsetFactor : 0.5,
        yOffsetFactor : 0.5,
        myImage: null,
        loading: false
    }
    
    componentDidMount() {

        this.ctx = this.canvas.getContext("2d");
        this.initKaleidoskop();

        this.onZoomChange = this.onZoomChange.bind(this);

        // Throttle onZoomChange calls to prevent flickering
        this.onZoomChange = _.throttle(this.onZoomChange, 25);
    
    }


    componentDidUpdate(prevProps) {
        // update on image change
        if (this.props.image !== prevProps.image) {
            this.changeImage(this.props.image)
                .then(() => this.createPattern());
        }
        if (this.props.zoom !== prevProps.zoom) {
              this.onZoomChange();
            
        }
        if (this.props.xOffsetFactor !== prevProps.xOffsetFactor || this.props.yOffsetFactor !== prevProps.yOffsetFactor) {
              this.createPattern();
        }
        if (this.props.width !== prevProps.width || this.props.height !== prevProps.height) {
            console.log('height / width change');
            this.updateMinScale()
                .then(() => this.updateMaxOffset())
                .then(() => this.createPattern());
        }
    }

    onZoomChange(){
        this.updateMaxOffset()
                .then(() => {
                    console.log('onZoomChange');
                    this.createPattern();
                });
    }
    

    /**
     * initial pattern creation on page load
     */
    initKaleidoskop(){
        this.changeImage(this.props.image)
        .then(() => this.createPattern());
    }

    /**
     * Change Image
     * 
     * Re-calculates min-scale and max offset
     * 
     * does not update the pattern
     * 
     * @param {string} newImage 
     */
    changeImage(newImage){

        this.setState({loading: true})

        return new Promise((resolve, reject) => {
            const imageUrl = newImage;
            const myImage = new Image();
            myImage.src = imageUrl;

            myImage.onload = () => { 
            const imageWidth = myImage.width;
            const imageHeight = myImage.height;


            this.setState( {imageWidth, imageHeight, myImage}, () => {
                this.updateMinScale()
                .then(() => this.updateMaxOffset())
                .then( () => {
                    this.setState({loading: false});
                    resolve();
                })
                // this.updateMaxOffset();
                // resolve();
            });
            }
        });

    }

    /**
     * Calculate the min scale
     * 
     * Must be called after changes to image or scale
     */
    updateMinScale(){

        const { onMinZoomChange } = this.props;

        return new Promise((resolve, reject) => {

            const { imageWidth, imageHeight } = this.state;
            const {height, width} = this.props;

            var absoluteMinHeight = height + (width / 9 * 2);

            var newScale = absoluteMinHeight / imageHeight; // min scale based on with
            
            console.log('Update min Scale', { height, width, absoluteMinHeight, imageHeight, newScale } );

            // set newScale based on with if scaled image is not wide enough
            if( imageWidth * newScale < width ) {
                newScale = width / imageWidth;

            }

            const zoom = newScale;
            const minZoom = newScale;
            this.setState( {zoom, minZoom}, () => {
                if(typeof onMinZoomChange === 'function') { 
                    onMinZoomChange(minZoom);
                }
                resolve();
            })

        });
    }

    /**
     * Update the max offset
     * 
     * Must be called after changes to image or scale
     */
    updateMaxOffset(){
        return new Promise((resolve, reject) => {
            const { imageWidth, imageHeight } = this.state;
            const { zoom } = this.props;
            const { height, width } = this.props;

            var virtualImageWidth = Math.round( imageWidth * zoom );
            var virtualImageHeight = Math.round(imageHeight * zoom);

            const maxXOffset = (virtualImageWidth - width) * -1;
            const maxYOffset = (virtualImageHeight - height - (width / 9 * 2) ) * -1;


            this.setState({maxXOffset, maxYOffset}, () => {
            resolve();
            });
        })

    }

    /**
     * Change image by drag 'n' drop / file upload
     * 
     * Wrapper for changeImage() method to support file upload
     * Converts uploaded file to data-url
     * 
     * @param {*} files 
     */
    setFiles(files){
        var file = files[0];

        if (typeof FileReader !== "undefined" && file.type.indexOf("image") !== -1) {
            var reader = new FileReader();
            // Note: addEventListener doesn't work in Google Chrome for this event
            reader.onload = (evt) => {
            var imageUrl = evt.target.result;
            this.changeImage(imageUrl)
                .then( () => this.createPattern() );
            // img.src = evt.target.result;
            };
            reader.readAsDataURL(file);
        }

    }


    createPattern(){
        const patternProperties = {...this.state, ...this.props }

        createPattern(this.ctx, patternProperties);
    }


    /**
     *  Wrapper for setState. updates max-offset and re-creates pattern after state update
     * 
     * @param {object} input 
     */
    onChangeZoom(input){
        this.setState(input, () => {
            this.updateMaxOffset()
            .then(this.createPattern())
            
        });
    }

    /**
     *  Wrapper for setState. re-creates pattern after state update
     * 
     * @param {object} input 
     */
    onChangeOffset(input){
        this.setState(input, () => {
        this.createPattern();
        });
    }

    /**
     * Handle drag on canvas
     * @param {*} e 
     */
    onDrag(e){
        e.stopPropagation();
        e.preventDefault();
    }


    saveFile(){
        this.canvas.crossOrigin = "anonymous";
        this.canvas.toBlob((blob) => {
            saveAs(blob, "hits-pattern.png");
        });
    }


    
    render() {
        
        const { loading } = this.state;
        const { height, width, downloadOnClick } = this.props;
        


        return (
            <div className="canvasWrapper">
                <canvas
                id="myCanvas"
                width={width}
                height={height}
                name="My Canvas"
                title="My Canvas"
                alt="My Canvas"
                ref={ref => (this.canvas = ref)}
                onDrop={e => this.props.onDropImage(e)}
                onDragEnter={e => this.onDrag(e) }
                onDragOver={e => this.onDrag(e) }
                onDragLeave={e => this.onDrag(e) }
                onClick = { e => { if( downloadOnClick ) this.saveFile(); } }
                ></canvas>
                {
                    loading && ( <div className="loader"><p>Loading</p></div>)
                }
               
            </div>
        )
    }
}
