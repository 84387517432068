import React, { Component } from 'react'



import './imageSearch.scss';
import myData from './demoImages.json';
import Pattern from './pattern'


export default class ImageSearch extends Component {

    appID = 'a7648fecc7d783cc304ea539446ef5cf125b95dd34113d21a333fdf48c43717c';

    state = {
        searchTerm : '',
        images: []
    }


    componentDidMount(){
        this.setState({
            images: myData
        });
    }

    onChangeSeachTerm(e){
        console.log('onChangeSEarchTerm', e);
    }

    onSearch(e){
        e.preventDefault();

        const { searchTerm } = this.state;

        fetch('https://api.unsplash.com/search/photos/?client_id=' + this.appID + '&query=' + searchTerm + '&per_page=100')
            .then(res => res.json())
            .then(data => {
                this.setState({ images: data.results });
            })
            .catch(err => {
                console.log('Error happened during fetching!', err);
            });

    }

    render() {

        const { searchTerm, images } = this.state;
        const { onSelect } = this.props;

        return (
            <div>
                <form onSubmit={e => this.onSearch(e)}>
                    <input 
                        className="searchInput"
                        type="search"
                        placeholder="Search term"
                        value={searchTerm}
                        onChange={(event) => this.setState({searchTerm: event.target.value})}
                        />
                        &nbsp; Insert a search term and press enter
                </form>
                <div className="imageResults">
                    {
                        images.length > 0 && images.map((image, index) => {
                            return (
                                <button
                                    key={index}
                                    onClick={() => onSelect(image.urls.full)}
                                    >
                                    <Pattern
                                        image={image.urls.thumb}
                                        width={300}
                                        height={200}
                                        ></Pattern>
                                    {/* <img 
                                        src={image.urls.thumb}
                                        alt={image.alt_description}
                                        /> */}
                                </button>
                            )
                        })
                    }

                </div>
            </div>
        )
    }
}
