import './App.scss';


import React, { Component } from 'react'

import ImageSearch from './compontents/ImageSearch';
import Dropdown from './compontents/Dropdown';

import Pattern from './compontents/pattern'

export default class App extends Component {

  mainPattern = null;


  state = {
    offsetRotation : 20.0,
    offsetScale : 1.0,
    offsetX : 0.0,
    offsetY : 0.0,
    radius : 220,
    slices : 20,
    zoom : 1.1,
    minZoom : 1,
    rotate : 0 * Math.PI / 180,
    width : 3508,
    height : 2480,
    imageWidth : null,
    imageHeight : null,
    maxXOffset : 0,
    maxYOffset : 0,
    xOffsetFactor : 0.5,
    yOffsetFactor : 0.5,
    halfHeight : 2480 / 2,
    halfWidth : 3508 / 2,
    myImage: null,
    loading: false,
    imageUrl: 'default.jpg'
  }

  constructor(props){
    super(props);
    this.onSelectImageByUrl = this.onSelectImageByUrl.bind(this);
    this.onDropImage = this.onDropImage.bind(this);
    this.onMinZoomChange = this.onMinZoomChange.bind(this);
  }

  componentDidMount() {

    // console.log(this.canvas);
    // this.ctx = this.canvas.getContext("2d");
    // console.log(this.ctx);
    // this.initKaleidoskop();

  }

  /**
   * Update the max offset
   * 
   * Must be called after changes to image or scale
   */
  updateMaxOffset(){
    return new Promise((resolve, reject) => {
      const { height, width, imageWidth, imageHeight, zoom } = this.state;

      var virtualImageWidth = Math.round( imageWidth * zoom );
      var virtualImageHeight = Math.round(imageHeight * zoom);

      const maxXOffset = (virtualImageWidth - width) * -1;
      const maxYOffset = (virtualImageHeight - height - (width / 9 * 2) ) * -1;


      this.setState({maxXOffset, maxYOffset}, () => {
        resolve();
      });
    })

  }

  /**
   * Change image by drag 'n' drop / file upload
   * 
   * Wrapper for changeImage() method to support file upload
   * Converts uploaded file to data-url
   * 
   * @param {*} files 
   */
  setFiles(files){
    var file = files[0];

    if (typeof FileReader !== "undefined" && file.type.indexOf("image") !== -1) {
      var reader = new FileReader();
      // Note: addEventListener doesn't work in Google Chrome for this event
      reader.onload = (evt) => {
        var imageUrl = evt.target.result;
        this.setState({imageUrl})
      };
      reader.readAsDataURL(file);
    }

  }

  /**
   * Handles Image Drop to canvas (for drag 'n' drop support)
   * 
   * calls onSetFile
   * @param {object} e 
   */
  onDropImage(e){
    e.stopPropagation();
    e.preventDefault();
    this.setFiles(e.dataTransfer.files);
    return false
  }

  /**
   * Change the image by url parameter
   * @param {string} url 
   */
  onSelectImageByUrl(imageUrl){
    const newUrl = 'https://bildkonzept.hits.werbeagenten.de/image.php?url=' + encodeURIComponent( imageUrl );
    console.log('onSelectImageByUrl', imageUrl, newUrl);
    // console.log(imageUrl);
    // console.log(encodeURIComponent( imageUrl ));
    this.setState({imageUrl: newUrl});
    // this.setState({imageUrl});
  }

  onMinZoomChange(newMinZoom){
    this.setState({minZoom: newMinZoom, zoom: newMinZoom})
  }


  render() {
    const {width, height, zoom, minZoom, xOffsetFactor, yOffsetFactor, imageUrl} = this.state;

    var maxXOffset, maxYOffset;
    if(this.mainPattern != null){
      maxXOffset = this.mainPattern.state.maxXOffset;
      maxYOffset = this.mainPattern.state.maxYOffset;
      console.log('Main Pattern', maxXOffset, maxYOffset);
    }else{
      maxXOffset = 0;
      maxYOffset = 0;
    }

    return (
      <div className="App">
        <img src="/logo.png" alt="logo" className="logo"/>

        <div className="wrapper">
          <div className="column column-1">

            
            <Dropdown title="Image sizes">
              <h3>Image Sizes</h3>
                
                <h4>Print</h4>
                <div className="button-group">
                  <button className="button image-size-button" onClick={ () => this.setState({width: 3508, height:2480}) }>Din A4 landscape</button>
                  <button className="button image-size-button" onClick={ () => this.setState({width: 2480, height:3508}) }>Din A4 portrait</button>
                </div>
                
                <h4>Digital</h4>
                <div className="button-group">
                  <button className="button image-size-button" onClick={ () => this.setState({width: 1920, height:1080}) }>Power Point</button>
                  <button className="button image-size-button" onClick={ () => this.setState({width: 1340, height:460}) }>Website header</button>
                </div>

                <h4>Social Media</h4>
                <div className="button-group">
                  <button className="button image-size-button" onClick={ () => this.setState({width: 820, height:360}) }>Facebook Cover Image</button>
                  <button className="button image-size-button" onClick={ () => this.setState({width: 1500, height:500}) }>Twitter Cover Image</button>
                </div>
                <h4>Manual Size</h4>
                <div className="manual-size">
                  <label>
                    Width
                  </label>

                  <input type="number" value={width} onChange={ e => { this.setState( {width: parseInt(e.target.value) } ) } }></input>
                  <label>
                    Height
                  </label>
                  <input type="number" value={height} onChange={ e => { this.setState( {height: parseInt( e.target.value ) } ) } }></input>
                </div>
            </Dropdown>

            {/* <label htmlFor="xOffset">Y-Offset</label> */}
            <p>Scale</p>
              <input 
                type="range"
                min={minZoom}
                max={minZoom * 5}
                step="0.01"
                value={zoom}
                id="scaleInput"
                onChange={ e => { this.setState( {zoom: e.target.value} ) } }
                ></input>
            <div className="mainPatternWrapper">
              <input
                type="range"
                min="0"
                max="1"
                step="0.001"
                className="verticalRange yAxis"
                value={yOffsetFactor}
                disabled = {( maxYOffset > -1 )? "disabled" : ""}
                onChange={ e => { this.setState( {yOffsetFactor: e.target.value} ) } }
                id="yOffset"
                />
                <Pattern
                  image={imageUrl}
                  width={width}
                  height={height}
                  zoom={zoom}
                  xOffsetFactor={xOffsetFactor}
                  yOffsetFactor={yOffsetFactor}

                  onDropImage = {this.onDropImage}
                  onMinZoomChange={this.onMinZoomChange}
                  downloadOnClick={true}

                  ref={ref => (this.mainPattern = ref)}

                  ></Pattern>
                    {/* <label htmlFor="xOffset">X-Offset</label> */}
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.001"
                    className="xAxis"
                    value={xOffsetFactor}
                    disabled = {( maxXOffset > -1 )? "disabled" : ""}
                    onChange={ e => { this.setState( {xOffsetFactor: e.target.value} ) } }
                    id="xOffset"
                    />
              
            </div>


              <div className="button-group">
                <button className="button" onClick={e => this.mainPattern.saveFile() }>Download as PNG</button>
              </div>

              
              
              

                

            </div> {/* .column  */}
            <div className="column">
              <div className="upload-file-wrapper">
                <p>Upload image</p>
                <input
                  type="file"
                  id="fileUpload"
                  accept=".jpg,.png"
                  className=""
                  onChange={ (e) => {
                    if(e.target.files.length > 0){
                      this.setFiles(e.target.files);
                    }
                  }}
                  />
                  <br></br>
                  <p>Or search for stock images</p>
                </div>
              <ImageSearch onSelect={this.onSelectImageByUrl}></ImageSearch>
            </div>
          </div>



      </div>



    );
  }

}
