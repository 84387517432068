export default ( ctx, data ) => {

    const { maxXOffset, xOffsetFactor, maxYOffset, yOffsetFactor, width, height, zoom, rotate, myImage} = data;

    var xOffset = maxXOffset * xOffsetFactor;
    var yOffset = maxYOffset * yOffsetFactor;

    var partWith = width / 9;

    var pat = ctx.createPattern(myImage, "repeat");
    ctx.fillStyle = pat;


    // first
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.rect(0, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , 0);
    ctx.scale(zoom, zoom);
    ctx.fill();


    // second
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , -1 * partWith);
    ctx.scale(zoom, zoom);
    ctx.fill();


    // third
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith * 2, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , partWith);
    ctx.scale(zoom, zoom);
    ctx.fill();

    // 4th
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith * 3, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , 0);
    ctx.scale(zoom, zoom);
    ctx.fill();


    // 5th
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith * 4, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , -1 * partWith);
    ctx.scale(zoom, zoom);
    ctx.fill();


    // 6th
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith * 5, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , partWith);
    ctx.scale(zoom, zoom);
    ctx.fill();



    // 7th
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith * 6, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , 0);
    ctx.scale(zoom, zoom);
    ctx.fill();

    // 8th
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith * 7, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , -1 * partWith);
    ctx.scale(zoom, zoom);
    ctx.fill();

    // 8th
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.beginPath();
    ctx.rect(partWith * 8, 0, partWith, height);
    // ctx.rotate(0 * Math.PI / 180);
    ctx.rotate(rotate);
    ctx.translate(0 , -1 * partWith); //default translate for 
    ctx.translate(xOffset , yOffset);
    ctx.translate(0 , partWith);
    ctx.scale(zoom, zoom);
    ctx.fill();


}