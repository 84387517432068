import React, { Component } from 'react'

import './style.scss'

export default class Dropdown extends Component {

    state = {
        isOpen : false
    }

    render() {

        const { title, children } = this.props;
        const { isOpen } = this.state;

        return (
            <div className="dropdown">
                <a href="#" className="toggle" onClick={(e) => { e.preventDefault(); this.setState( { isOpen: ! isOpen} ) }}>
                <img 
                    src="data:image/svg+xml;base64,
PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDQ1OSA0NTkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1OSA0NTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48Zz4KCTxnIGlkPSJtZW51Ij4KCQk8cGF0aCBkPSJNMCwzODIuNWg0NTl2LTUxSDBWMzgyLjV6IE0wLDI1NWg0NTl2LTUxSDBWMjU1eiBNMCw3Ni41djUxaDQ1OXYtNTFIMHoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6IzAwNDc4NCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcGF0aD4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+"
                    width="15"
                    />
                   { title }
                </a>

                {
                    isOpen && (
                        <div className="content">
                            { children }
                        </div>
                    )
                }
                {
                    isOpen && (
                        <div className="overlay" onClick={(e) => { e.preventDefault(); this.setState( { isOpen: ! isOpen} ) }}>
                        </div>
                    )
                }
                
            </div>
        )
    }
}
